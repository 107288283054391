<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
  />
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const cmd = this.$store.state.cmd
    return {
      cmd,
      formData: {
        subjectType: 'PC_MANAGE',
      },
      config: [
        // lang:角色名称
        {
          label: this.$t('roleManage.name'),
          prop: 'name',
          rules: {
            noNull: true,
          },
        },
        // lang:角色类型
        {
          tag: 'el-select',
          label: this.$t('roleManage.type'),
          prop: 'roleType',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.roleType(),
            disabled: cmd !== 'add',
          },
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
          tagProps: {
            type: 'textarea',
            rows: 4,
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
        } else {
          const operator = this.$util.getSessionStorage('operator')
          const { company } = operator
          const { level, canAddSubCompany } = company
          if (level !== 'ONE') {
            if (!canAddSubCompany || canAddSubCompany === 'NO') {
              const item = this.$dict.roleType()[1]
              this.config[1].tagProps.options = [item]
              this.config[1].tagProps.disabled = true
              this.formData.roleType = 'SUB_MANAGER'
            } else {
              this.formData.roleType = 'COMPANY_MANAGER'
            }
          } else {
            this.formData.roleType = 'COMPANY_MANAGER'
          }
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      console.log(this.cmd)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.roleAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.roleUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>